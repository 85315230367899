.membership-banner-container {
    background-image: linear-gradient(to bottom, rgba(68, 68, 70, 0.52), rgba(7, 7, 7, 0.73)), url(".././assets/membershipbanner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    width: 100%;
}


.membership-banner {
    text-align: center;
    display: flex;
    justify-content: center;
    padding-top: 200px;
    padding-bottom: 200px;
    color: var(--offwhite);
}


.sub-container {
    padding: 30px;
}

.membership-section {
    display: flex;
    justify-content: space-between;
    align-items: top;
    flex-wrap: wrap;
    margin-top: 60px;
    margin-bottom: 60px;
}

.membership-section > * {
    flex:1;
   flex-basis: 50%;
}

@media screen and (max-width:853px) {
    .membership-section > * {
        flex-basis: 100%;
        list-style: none;
    }
    .membership-section {
        gap: 15px;
    }
}

.memberships {
    color: var(--offwhite);
    background: var(--tertiarycolour);
    text-align: center;
}

#membership-categories-section {
    color: var(--offwhite);
    background: var(--tertiarycolour);
}

.membership-categories {
    padding-top: 60px;
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    gap: 15px;
}

.membership-cetegories > * {
    flex:1;
    flex-basis: 33%;
    
}

.category-icon {
    border: none;
    width:250px;
}

.membership-category {
    flex: 1;
    flex-basis: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    max-width: 350px;
}