.featured-events {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.carousel-events {
    display: none;
}

.events-section {
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 25px;
    padding: 20px;
}

.events-view-more:hover {
    color:var(--secondarycolour);
}


@media screen and (max-width: 920px) {
    .carousel-events {
        display: flex;
        overflow-x: hidden;
        overflow-y: visible;
        padding-bottom: 30px;
    }

    .events-section {
        display: none;
    }
    
    .embla-events-container {
        width: 90vw;
        display: flex;
        gap: 15px;
        padding: 15px;
    }

}


    
