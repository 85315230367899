.aboutus-banner-container {
    background-image: linear-gradient(to bottom, rgba(68, 68, 70, 0.52), rgba(7, 7, 7, 0.73)), url(".././assets/boardmembers/BANNER.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
}

.light {
    color:red;
}

.dark {
    color: yellow;
}


.aboutus-banner {
    display: flex;
    justify-content: center;
    padding-top: 200px;
    padding-bottom: 200px;
    color: var(--offwhite);
}

.aboutus-details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 30px;
    max-width: 1440px;
}

#aboutus-left h3 {
    margin-bottom: 15px;
}

#aboutus-left {
    flex: 1;
    flex-basis: 50%;
}

#aboutus-story {
    flex: 1;
    flex-basis: 50%;
}

#aboutus-story p {
    margin-top: 15px;
    line-height: 1.5;
}

@media screen and (max-width: 990px) {
    .aboutus-details {
        gap: 20px;
    }

    #aboutus-left,
    #aboutus-story {
        flex-basis: 100%;
    }
}

#our-mission-container {
    background: var(--tertiarycolour);
}

.our-mission {
    
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    padding: 30px;
    color: var(--offwhite);
    margin-bottom: 60px;
}

.our-mission #mission-description {
    max-width: 600px;
    text-align: center;
    line-height: 1.5;
}


.mission-selector {
    display: flex;
    gap: 20px;
    border-bottom: 3px solid var(--offwhite);
    z-index: 1;
}

#core-values-button,
#code-of-ethics-button {
    width: 300px;
    text-align: center;
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--offwhite);
}

.mission-selector input[type="radio"]:checked + label {
    
    color:var(--offwhite);
    width: 300px;
    text-align: center;
    border-bottom: 3px solid var(--offwhite);
}

input {
    display: none;
}

input[type="radio"]:checked{
    visibility:hidden;
}

.mission-selector input[type="radio"]:not(:checked) + label {
    width: 300px;
    text-align: center;
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--offwhite);
}




#ethics-list {
    text-align: left;
    font-size: 20px;
}

#code-of-ethics-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#code-of-ethics-text ul {
    padding:30px;
    columns: 2;
    column-gap: 30px;
}

@media screen and (max-width: 970px) {
    #code-of-ethics-text ul {
        columns: 1;
    }
}

@media screen and (max-width: 1209px) {
    .rental-description {
        flex-basis: 100%;
    }
}

@media screen and (max-width: 660px) {

    #core-values-button,
    #code-of-ethics-button {
        width: 150px;
    }

    input[type="radio"]:not(:checked) + label {
        width: 100px;
       
    }

    .mission-selector input[type="radio"]:checked + label {
        width: 150px;
        text-align: center;
        background: transparent;
        border: none;
        cursor: pointer;
        color: var(--offwhite);
    }

    .mission-selector input[type="radio"]:not(:checked) + label {
        width: 150px;
        text-align: center;
        background: transparent;
        border: none;
        cursor: pointer;
        color: var(--offwhite);
    }
}

.mission-body-text{
    max-width: 1440px;
    text-align: center;
    line-height: 1.5;
}

#boardmembers-list {

    display: flex;
    justify-content: space-between;
    gap: 30px;
    max-width: 1440px;
    flex-wrap: wrap;
    padding: 30px;
}

#boardmembers-list > * {
    flex:1;
    flex-basis: 21%;
}

#constitutions-bylaws-container {
    background: var(--tertiarycolour);
}

#constitutions-bylaws {
    text-align: center;
    gap: 15px;
    max-width: 1440px;
    
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--offwhite);
}

#preamble-section {
    max-width: 1000px;
    padding-left: 30px;
    padding-right: 30px;
}

#preamble-section > * {
    margin-bottom: 10px;
}

#constitution-list  > * > *{
 
}

@media screen and (max-width: 930px) {
    #constitution-list > * > * {
        flex: 1;
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #preamble-section > * {
        margin-bottom: 20px;
    }
}

#constitutions-bylaws ul {
    display: flex;
    flex-wrap: wrap;
    
    justify-content: space-between;
    list-style-type: none;
}

#constitutions-bylaws li {
    margin: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
}

/* #constitution-bylaws li {
    position: relative
}

#constitutions-bylaws li::before {
    content: "→";
} */

.google-reviews {
    display: flex;
    width: 100%;
    height: 600px;

}

.google-reviews iframe{
    display: flex;
}
