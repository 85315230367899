.events-banner-container {
    background-image: linear-gradient(to bottom, rgba(68, 68, 70, 0.52), rgba(7, 7, 7, 0.73)),url(".././assets/eventsbanner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
}


.events-banner {
    display: flex;
    justify-content: center;
    padding-top: 200px;
    padding-bottom: 200px;
    color: var(--offwhite);
}

.upcoming-events-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    padding: 30px;
}


.upcoming-events {
    display: flex;
    max-width: 1440px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 60px;
}

.upcoming-events > * {
    flex: 1;
    flex-basis: 20%;
}

@media screen and (max-width: 1390px) {
    .upcoming-events {
        justify-content: center;
    }
}


