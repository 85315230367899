.boardmembers-preview-container {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7)), url("../assets/boardmembers-bg.png") no-repeat;
    background-size: cover;
    padding-bottom: 60px;
    
}

.boardmembers-preview {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 1440px;
    padding: 30px;
}
.boardmembers-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
}

.boardmembers-section > * {
    flex: 1;
    flex-basis: 20%;
}

.view-more {
    margin-top: 60px;
    font-size: 20px;
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 60px;
}

.board-members-view-more:hover {
    color:var(--secondarycolour);
}

@media screen and (max-width: 1120px) {
    .view-more {
        margin-top: 20px;
        font-size: 20px;
        font-weight: bold;
        text-decoration: underline;
        margin-bottom: 20px;
    }
}


