.rental-banner-container {
    background-image: linear-gradient(to bottom, rgba(68, 68, 70, 0.52), rgba(7, 7, 7, 0.73)), url(".././assets/rentalbanner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    margin-bottom: 60px;
}


.rental-banner {
    display: flex;
    justify-content: center;
    padding-top: 200px;
    padding-bottom: 200px;
    color: var(--offwhite);
}

.rental-selector {
    display: flex;
    gap: 20px;
    border-bottom: 3px solid var(--tertiarycolour);
    z-index: 1;
}

#banquet-hall-button,
#boardroom-button {
    width: 300px;
    text-align: center;
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--offblack);
}

#banquet-hall-button:active {
    border-bottom: 3px solid var(--tertiarycolour);
    z-index: 1;
}

#banquet-hall-button:focus {
    border-bottom: 3px solid var(--tertiarycolour);
    z-index: 1;
}

input[type="radio"]:not(:checked) + label {
    width: 300px;
    text-align: center;
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--offblack);
}

input[type="radio"]:checked + label {
    width: 300px;
    text-align: center;
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--offblack);
    border-bottom: 3px solid var(--tertiarycolour);
}

.rental-title {}

.rental-section {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
    max-width: 1440px;
    
    padding: 30px;
}

.rental-details {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
}

.rental-image-container {
    flex: 1;
    flex-basis: 45%;
}

.rental-image {
    width: 100%;
}

.rental-description {
    flex: 1;
    flex-basis: 39%;
    /* min-width: 632px; */
    display: flex;
    flex-direction: column;
    gap: 30px;
    line-height: 1.5;


}


.rental-description p {
    word-wrap: break-word;
    max-width: 100%;
}

.rental-features-details {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.rental-features-details-child {
    flex: 1;
    flex-basis: 30%;
}

.rental-features-details-child ul {
    line-height: 1.5;
    font-size: 20px;
    list-style-type: none;
}

.rental-agreement-btn {
    text-decoration: underline;
    font-weight: bold;
}

#rates-section,
#additionals-section {
    border-left: 5px var(--tertiarycolour) solid;
    padding-left: 10px;
}

@media screen and (max-width: 1209px) {
    .rental-description {
        flex-basis: 100%;
    }
}

@media screen and (max-width: 660px) {
    .rental-features-details-child {
        flex-basis: 100%;
        margin-bottom: 20px;
    }

    #rates-section,
    #additionals-section {
        border-left: none;
        padding-left: 0;
    }

    #banquet-hall-button,
    #boardroom-button,
    input[type="radio"]:checked + label,
    input[type="radio"]:not(:checked) + label {
        width: 150px;
    }
}