#contact-form {
    margin-top: 60px;
    margin-bottom: 60px;
    
}

#contact-form > * {
    
}

.contact-banner-container {
    background-image: linear-gradient(to bottom, rgba(68, 68, 70, 0.52), rgba(7, 7, 7, 0.73)),url(".././assets/contactbanner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
}


.contact-banner {
    display: flex;
    justify-content: center;
    padding-top: 200px;
    padding-bottom: 200px;
    color: var(--offwhite);
    
}

#contact-fields {
    margin-bottom: -10px;
    display:flex;
}