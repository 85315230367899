/* .event-card {
    flex: 1;
    background: #F6F6F6;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    max-width: 350px;
    min-width: 290px;
    
}

.event-picture {
    width: 85%;
    height: 230px;
    object-fit: cover;
    margin-top:20px;
    border-radius: 20px;
}

.event-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.event-description, .event-title, .event-date, .learn-more {
    max-width:250px;
    margin: 10px;
}

.event-title {
    font-weight: bold;
    margin-top: 20px;
}

.event-date {
    color: gray;
}

.learn-more {
    text-decoration: underline;
    font-weight: bold;
    margin-bottom: 20px;
}

.learn-more:hover{
    cursor: pointer;
    color: var(--secondarycolour);
}

@media screen and (max-width: 920px) {
    .event-card {
        width: 250px;
        flex:0;
    }

    .event-details {
        width: 225px;
    }
    
}
 */

 .event-card {
    max-width: 309px;
    flex: 1;
    background: #F6F6F6;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    padding: 15px;
}

.event-picture {
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.event-img {
    padding: 80px 0;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
    width: 100%;
    transition: .3s ease;
}

.event-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.event-description, .event-title, .event-date, .learn-more {
    margin: 10px;
}

.event-title {
    font-weight: bold;
    margin-top: 20px;
}

.event-date {
    margin-top:5px;
    color: gray;
}

.learn-more {
    text-decoration: underline;
    font-weight: bold;
}
.event-card:hover .event-img {
    
    background-size: 110%;
}

.event-img {
    /* background-image: url(".././assets/featuredevent1.jpg"); */
}

@media screen and (max-width: 920px) {
    .event-card {
        width: 250px;
        flex:0;
    }

    .event-details {
        width: 225px;
    }
    
}
