.boardmember-card {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.boardmember-picture {
    max-width: 348px;
    width: 100%;
    height: 350px; 
    border-left: 10px solid var(--secondarycolour);
    border-bottom: 10px solid var(--secondarycolour);
    z-index: 0;
    object-fit: cover;
}

.boardmember-details {
    /* width: 340px; */
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    background: white;
    margin-top: -40px;
}

.boardmember-name {
    padding-top: 30px;
    font-weight: bold;
}

.boardmember-name,
.boardmember-position,
.boardmember-description {
    margin-bottom: 15px;
}

.boardmember-description {
    width: 280px;
    text-align: center;
}

.boardmember-position {
    color: gray;
}

@media only screen and (max-width: 700px) {
    .boardmember-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 15px;
    }

    .boardmember-picture {
        width: 300px;
        height: 300px;
        border-left: 10px solid red;
        border-bottom: 10px solid red;
        z-index: 0;
    }

    .boardmember-details {
        width: 259px;
        height: 265px;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
        background: white;
        margin-top: -40px;
    }

    .boardmember-name {
        padding-top: 30px;
        font-weight: bold;
    }

    .boardmember-name,
    .boardmember-position,
    .boardmember-description {
        margin-bottom: 15px;
    }

    .boardmember-description {
        width: 228px;
        text-align: center;
    }

}