.gallery-preview-container {
    background: rgb(230, 230, 230);
    margin-top: -60px;
    padding-top: 60px;
}

.gallery-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 15px;
}
.gallery-preview {
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 120px;
}

.gallery-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.gallery-picture, .view-more-gallery {
    width: 430px;
    height: 250px;
    margin:5px;
    border-radius: 20px;
}

.view-more-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing:border-box;
    border: 2px var(--offblack) solid;
    
}

.gallery-carousel {
    display: none;
}

@media screen and (max-width: 750px) {
    .gallery-section {
        display: none;
    }

    .gallery-carousel {
        display: flex;
        overflow: hidden;
    }

    .embla-gallery {
        overflow: hidden;
    }

    .embla-gallery-container {
        display: flex;
        overflow: visible;
        gap: 15px;
        width: 100vw;
    }

    .embla-gallery-slide {
        width: 100%;
        min-width: 430px;
    }
    
    
}




