#advertise-banner {
    display:flex;
    align-items: center;
    background: var(--secondarycolour);
    text-align: left;
    color:var(--offwhite);
    
}

#ad-img-container {
    flex:1;
    flex-basis: 50%;
    
}

#advertise-pic {
   width: 100%;
}

#ad-details {
    flex:1;
    flex-basis: 50%;
    display: flex;
    justify-content: center;
}

#ad-text {
    max-width: 500px;
}