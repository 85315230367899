.news-announcements-preview {
    background: url("../assets/newsannouncementspreview.png") no-repeat;
    background-size:cover;
    background-position:bottom;
}


/*news items*/
/* .substack-feed > * > * {
    border: red 2px solid;
}

.substack-feed > *:nth-child(1) {
    display: none;
}

.substack-feed > * {
    
    display: flex;
    flex-direction: column;
    gap: 15px;
} */


.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.desktop-news {
    margin-bottom: -40px;
    
}

.section-title {
    font-weight: 500;
    text-align: center;
}

.news-view-more {
    color: var(--offwhite);
    text-decoration: none;
    margin-bottom: 30px;
}


.news-view-more:hover {
    color: var(--offwhite);
}

.news-carousel {
    display: none;
}

.embla-news {
    display: none;
}

/* @media screen and (max-width: 750px) {
    .desktop-news {
        display: none;
    }

    .news-carousel {
        display: flex;
        overflow: hidden;
        margin-bottom: -30px;
    }

    .embla-news {
        display: flex;
    }

    .carousel-news {
        display: flex;
        overflow-x: hidden;
        overflow-y: visible;
        padding-bottom: 30px;
        
    }

    
    .embla-news-container {
        width: 90vw;
        display: flex;
        gap: 15px;
        padding: 15px;
    } 


}*/
