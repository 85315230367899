.news-banner-container {
    background-image: linear-gradient(to bottom, rgba(68, 68, 70, 0.52), rgba(7, 7, 7, 0.73)), url(".././assets/newsbanner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    width: 100%;
}


.news-banner {
    text-align: center;
    display: flex;
    justify-content: center;
    padding-top: 200px;
    padding-bottom: 200px;
    color: var(--offwhite);
}

#news-parent {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin-bottom: 100px;
}

#pccm-newsletter {
    margin-bottom: 60px;
    margin-top: 60px;
}


/* #news-blog {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
}

#news-blog iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
} */


