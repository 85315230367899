.gallery-page {
    margin-bottom: 70px;
}

.paginate {
    display: flex;
    width: 400px;
    justify-content: space-between;
    margin-bottom: 100px;
}

.paginate li {
    list-style-type: none;
}

.paginate a:hover {
    cursor: pointer;
    color: var(--secondarycolour);
}

.paginate {
    margin-top: 30px;
    
}
.test {
   display: flex;
   flex-wrap: wrap;
   padding: 15px;
   justify-content: space-evenly;
   row-gap: 30px;
}


.gallery-page-section {
    margin: 15px;
    max-width: 1440px;
    width: 100%;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.gallery-banner-container {
    background-image: linear-gradient(to bottom, rgba(68, 68, 70, 0.52), rgba(7, 7, 7, 0.73)),url(".././assets/gallerybanner2.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    background-position:center;
    width: 100%;
}


.gallery-banner {
    display: flex;
    justify-content: center;
    padding-top: 200px;
    padding-bottom: 200px;
    color: var(--offwhite);
}

@media screen and (max-width: 595px) {
    
}
