.main {
    height: 100%;
}

.footer-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    background: var(--tertiarycolour);
    position: relative;
    
}
.footer {
    max-width: 1440px;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex;
    font-size: 20px;
    color: white;
    z-index: 2;
    padding: 30px;
}

.footer-labels {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.text-under-logo {
    width: 320px;
}

.footer-logo {
    width: 100px;
}

.left-side {
    width: 40%;
}

.ig-logo {
    width: 35px;
}

.right-side {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 60%;
}

.footer-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 100px;
}

.footer-section li {
    margin-bottom: 20px;
    font-weight: 400;
}

.footer-contacts {
    display: flex;
    gap: 15px;
}

.footer-title {
    font-size: 24px;
}

.footer-copyright {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.footer-copyright-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondarycolour);
    color: var(--offwhite);
}

.copyright-section {
    margin:20px;
    height: 100%;
    max-width: 1440px;
    width: 100%;
}

.privacy-terms {
    min-width: 30%;
    display: flex;
    justify-content: space-between;
}

.footer a:hover {
    color: var(--offwhite);
    text-decoration: underline;
}

.footer li {
    list-style: none;
}

@media screen and (max-width: 1128px) {
   .footer {
     height: auto;
   }
    .footer-labels {
        flex-direction: column;
    }

    .footer-section {
        padding-top: 30px;
    }

    .right-side {
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
    }

    .left-side {
        align-self: flex-start;
    }

    .footer-copyright {
        flex-direction: column;
        gap: 15px;
    }

    .privacy-terms {
        flex-direction: row;
        justify-content: flex-start;
        gap: 10px;
    }
}